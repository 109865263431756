
import {computed, defineComponent, ref} from "vue";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter
} from "@ionic/vue";
import {useRoute} from "vue-router";
import {User} from "@/domain/model/user/user.model";
import {useStore} from "vuex";

export default defineComponent({
  name: "UserBonusDetailPage",
  components: {
    IonPage,
    IonToolbar,
    IonHeader,
    IonBackButton,
    IonButtons,
    IonTitle,
    IonContent,

  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const id = ref(route.params.id);
    const user = computed<User>(() => store.getters.userById(id.value));



    onIonViewWillEnter(()  => {
      if (id.value === route.params.id)
        return;
      id.value = route.params.id;
    })
    return {
      user,
    };
  }
})
